<template>
	<div id="main-wrapper" class="inner-pages forex-page pamm-page white-page">
		<div class="section banner-holder">
			<div class="forex-nav">
				<div class="wrapper">
					<ul>
						<li class="active"><router-link to="/white-label">White Label</router-link></li>
						<!-- <li><router-link to="/pamm">PAMM/MAM</router-link></li> -->
						<!-- <li><router-link to="/ib">Introducing Broker</router-link></li> -->
					</ul>
				</div>
			</div>
			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>White Label</h2>
				<p>Our experience, Your success!</p>
			</div>
		</div>
		<div class="pamm-content section">
			<div class="wrapper">
				<div class="title-holder wow fadeIn">
					<h5>A unique opportunity to set up your own brokerage, with the help of our knowledge, experience, and support.</h5>
				</div>
				<div class="content-holder wow fadeInLeft">
					<div class="title-holder">
						<p>A white-label program is a great way to set up a strong position quickly in market. This is possible with the use of our technology, impeccable trading conditions, support services and useful back-office CRM tools.
						<br><br>
						The AAAFx team is dedicated to your success through our technical and professional support.
						<br><br>
						Our white label program offers you full flexibility to design your products and services, so that you can get the best opportunity to flourish your venture.
						<br><br>
						Choosing the right broker is the first step in accomplishing your desired goals.
						<br><br>
						Gear up and make the right choice now!
						</p>
					</div>
				</div>
				<div class="img-holder wow fadeInRight">
					<img src="assets/images/white-img1.webp" alt="AAAFx" title="AAAFx" width="393" height="405" class="fluid-img" />
				</div>
			</div>
		</div>
		<div class="expect-sec section">
			<div class="wrapper">
				<div class="img-holder wow fadeInLeft">
					<img src="assets/images/white-img2.webp" alt="AAAFx" title="AAAFx" width="620" height="367" class="fluid-img" />
				</div>
				<div class="content-holder wow fadeInRight">
					<div class="title-holder">
						<h3>What to expect?</h3>
						<ul class="listing-check">
							<li>Collaboration with Largest Social Trading Network</li>
							<li>Dedicated Account Managers for Investors and Managers</li>
							<li>Flexibility in setting parameters and conditions for the account </li>
							<li>Ultra-low commissions with raw spreads</li>
							<li>Security of funds with a trusted broker </li>
							<li>Small investment required to start</li>
							<li>Transparency for the investors- no hidden clauses</li>
							<li>Full control to investors related to deposits and withdrawals</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="section bg-img">
			<div class="wrapper"></div>
		</div>
		<div class="section-small ">
			<div class="wrapper">
				<div class="title-holder">
					<h6>Ready to receive customized solutions for your new endeavor? </h6>
					<br><br>
					<p>
						Contact Us now <a href="mailto:support@aaafx.com.">here</a>  or write to us at <br>
						<a href="mailto:support@aaafx.com.">support@aaafx.com.</a>
					</p>
				</div>
			</div>
		</div>

	</div>
</template>